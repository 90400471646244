import { PRODUCT } from "@shared/utilities/constants/Product";
import { useProduct } from "@shared/utilities/contexts/Product";
import typeProduct from "@shared/utilities/types/Product";
import useBranchLink from "@/utilities/hooks/useBranchLink";

interface ProductLinkProps {
  branchLink: string;
  product: typeProduct['type'];
}

const useProductLink = (props: ProductLinkProps) => {
  const { branchLink, product } = props;

  const context: typeProduct['override'] = useProduct();

  const { withAmpDev } = useBranchLink();

  const appStoreLink = branchLink
  ? withAmpDev(branchLink)
  : context &&
      context.override &&
      context[product] &&
      context[product]?.appStore
    ? withAmpDev(context[product]?.appStore as string)
    : withAmpDev(PRODUCT[product].appStore)

  const googlePlayLink = branchLink
  ? withAmpDev(branchLink)
  : context &&
      context.override &&
      context[product] &&
      context[product]?.googlePlay
    ? withAmpDev(context[product]?.googlePlay as string)
    : withAmpDev(PRODUCT[product].googlePlay)

  return {
    appStoreLink,
    googlePlayLink
  }
}

export default useProductLink;