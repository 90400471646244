import styles from './Layout.module.scss';

// ... Shared Package Components
import { Footer } from '@shared/components/organisms/Footer';
import { Header } from '@shared/components/organisms/Header';
import { useBanners } from '@shared/utilities/hooks/useBanners';
import { BannerDetails } from '@shared/utilities/types/Banners';
import { typeWebsite } from '@shared/utilities/types/Website';
import Product from '@shared/utilities/contexts/Product';
import typeProduct from '@shared/utilities/types/Product';
import typeMenus from '@shared/utilities/types/Menus';
import typeStore from '@shared/utilities/types/Store';

import { SpeedInsights } from '@vercel/speed-insights/next';
import { useLocale } from '@/utilities/contexts/Locale';
import { useWebsite } from '@/utilities/contexts/Website';
import Breadcrumbs from '@/components/molecules/Breadcrumbs';
import cn from 'classnames';
import GTM from '@/components/tracking/GTM';
import Main from '@/components/organisms/Main';
import Meta from '@/modules/Meta';
import Pagination from '@/components/molecules/Pagination';
import Status from '@/components/molecules/Status';
import typeBreadcrumbs from '@/utilities/types/Breadcrumbs';
import typeCustomiseLayout from '@/utilities/types/LayoutCustomise';
import typePagination from '@/utilities/types/Pagination';
import typeSeo from '@/utilities/types/Seo';
import typeStatus from '@/utilities/types/Status';
import WEBSITE from '@/utilities/constants/Website';
import useProductLink from '@/utilities/hooks/useProductLink';

interface LayoutProps {
  breadcrumbs?: typeBreadcrumbs[];
  children: React.ReactNode;
  className?: string;
  menus: typeMenus;
  pagination?: typePagination;
  product?: typeProduct['override'];
  seo: typeSeo;
  status?: typeStatus['status'];
  store?: typeStore;
  customise?: typeCustomiseLayout;
  notification?: {
    critical: BannerDetails;
    nonCritical: BannerDetails;
  };
}

const Layout = (props: LayoutProps) => {
  const {
    breadcrumbs,
    children,
    className,
    menus,
    pagination,
    product,
    seo,
    status,
    store,
    customise,
  } = props;

  const website = useWebsite();
  const banners = useBanners();
  const locale = useLocale();

  const speedInsightsPath = process.env.NEXT_PUBLIC_SPEED_INSIGHTS_PATH;
  const endpoint = `${speedInsightsPath}/vitals`;
  const scriptSrc = `${speedInsightsPath}/script.js`;

  const safetycultureProductLinks = useProductLink({
    branchLink: '',
    product: "safetyculture"
  });

  const sheqsyProductLinks = useProductLink({
    branchLink: '',
    product: "sheqsy"
  });

  return (
    <>
      {speedInsightsPath &&
        <SpeedInsights endpoint={endpoint} scriptSrc={scriptSrc} />
      }
      {WEBSITE[website].gtm && <GTM.Noscript />}
      <Product product={product}>
        <div className={cn(className, styles.root)}>
          <Meta seo={seo} />
          <Header
            banners={banners}
            menus={{
              header: menus.header,
            }}
            customise={{
              downloadApp: customise?.header.downloadApp,
              languageToggle: customise?.header.languageToggle,
              notification: customise?.header.notification, // ... keep it for feature notification functionality
              navigationStatus: customise?.header.navigationStatus,
              loginAndSignUpButtonsStatus: customise?.header.loginAndSignUpButtonsStatus,
              additionalLogo: {
                logo: {
                  altText: customise?.header?.additionalLogo
                    ? customise?.header?.additionalLogo?.logo?.altText
                    : null,
                  guid: customise?.header?.additionalLogo
                    ? customise?.header.additionalLogo?.logo.guid
                    : null,
                },
              },
            }}
            translations={seo.translations?.filter(
              (translation) => !Object.values(translation).includes(null)
            )}
            store={store} 
            locale={locale} 
            website={website as typeWebsite}
            productLinks={{
              safetyculture: safetycultureProductLinks,
              sheqsy: sheqsyProductLinks
            }}
            getAppLink={WEBSITE[website].getAppLink}
          />
          <Main>
            {breadcrumbs && customise?.breadcrumbsOn && (
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            )}
            {children}
            {pagination && <Pagination pagination={pagination} />}
          </Main>
          <Footer
            menus={{
              footer: menus.footer,
              legal: menus.legal,
            }}
            customise={{
              mainMenu: customise?.footer.mainMenu,
              legalMenu: customise?.footer.legalMenu,
            }}
            website={website as typeWebsite}
            productLinks={{
              safetyculture: safetycultureProductLinks,
              sheqsy: sheqsyProductLinks
            }}
          />
          {status && <Status status={status} />}
        </div>
      </Product>
    </>
  );
};

export default Layout;